<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                New Vendor Evaluation
              </h5>
            </div>
          </div>
        </div>
        <v-card-text class="card-padding">
          <v-form ref="frm">
            <v-row>
              <v-col offset="10">
                <v-btn
                  @click="back"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  color="blue"
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    ml-2
                  "
                  >Back</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Ref</label
                >

                <v-text-field
                  v-model="editedItem.ref"
                  placeholder="Enter Ref"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  Manufacturing site</label
                >

                <v-text-field
                  v-model="editedItem.manufacturing_site"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Enter Manufacturing site"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  Office</label
                >

                <v-text-field
                  v-model="editedItem.office"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Enter Office"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Name of tech contact</label
                >

                <v-text-field
                  v-model="editedItem.name_of_tech_contact"
                  placeholder="Enter name of tech contact"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Tech job title</label
                >

                <v-text-field
                  v-model="editedItem.tech_job_title"
                  placeholder="Enter Tech job title"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Tech phone</label
                >

                <v-text-field
                  v-model="editedItem.tech_phone"
                  placeholder="Enter Tech phone"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Tech fax</label
                >

                <v-text-field
                  v-model="editedItem.tech_fax"
                  placeholder="Enter Tech fax"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Tech email</label
                >

                <v-text-field
                  v-model="editedItem.tech_email"
                  placeholder="Enter Tech email"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Name of sale contact</label
                >

                <v-text-field
                  v-model="editedItem.name_of_sale_contact"
                  placeholder="Enter name of sale contact"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Sale job title</label
                >

                <v-text-field
                  v-model="editedItem.sale_job_title"
                  placeholder="Enter Sale job title"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Sale phone</label
                >

                <v-text-field
                  v-model="editedItem.sale_phone"
                  placeholder="Enter Sale phone"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Sale fax</label
                >

                <v-text-field
                  v-model="editedItem.sale_fax"
                  placeholder="Enter Sale fax"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Sale email</label
                >

                <v-text-field
                  v-model="editedItem.sale_email"
                  placeholder="Enter Sale email"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Name of product supplied</label
                >

                <v-text-field
                  v-model="editedItem.product_name_supplied"
                  placeholder="Enter name of product supplied"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Product code
                </label>

                <v-text-field
                  v-model="editedItem.product_code"
                  placeholder="Enter Product code"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Country of origin</label
                >

                <v-text-field
                  v-model="editedItem.country_of_origin"
                  placeholder="Enter Country of origin"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Other items produced</label
                >

                <v-text-field
                  v-model="editedItem.other_items_produced"
                  placeholder="Enter Other items produced"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Date</label
                >

                <v-menu
                  ref="mnu_date"
                  v-model="mnu_date"
                  :close-on-content-click="false"
                  :return-value.sync="mnu_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.created_date"
                      :disabled="true"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Date"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="green lighten-1"
                    header-color="primary"
                    v-model="date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnu_date = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.mnu_date.save(editedItem.created_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <label class="text-md text-typo font-weight-bolder ms-1 mt-3"
                  >Company certification (ISO9000/ ISO 14000/ HACCP/GMP
                  etc)</label
                >
              </v-col>
              <v-col cols="12" md="3">
                <!-- <p>{{ editedItem.company_certification }}</p> -->
                <!-- <v-radio-group
                  v-model="editedItem.company_certification"
                  :mandatory="true"
                  inline
                >
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group> -->

                <v-radio-group
                  class="mt-0"
                  row
                  v-model="editedItem.company_certification"
                >
                  <v-radio
                    on-icon="$checkboxOn"
                    off-icon="$checkboxOff"
                    label="Yes"
                    color="green"
                    :value="true"
                  >
                  </v-radio>
                  <v-radio
                    on-icon="$checkboxOn"
                    off-icon="$checkboxOff"
                    label="No"
                    color="red"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row v-if="editedItem.company_certification">
              <v-col cols="6">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  Attach the copy of the certificate</label
                >
                <v-file-input
                  prepend-inner-icon
                  v-model="file"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-3
                  "
                  dense
                  flat
                  filled
                  solo
                  height="46"
                  placeholder="Upload file"
                ></v-file-input>
              </v-col>
            </v-row>

            <!-- Question  -->
            <v-container class="px-0">
              <v-simple-table>
                <template v-slot:default>
                  <tr v-for="(item, i) in questions" :key="item.id">
                    <td>{{ item.question }}</td>
                    <td>
                      <v-checkbox
                        v-model="ans[i]"
                        label="Yes"
                        color="green"
                        value="true"
                        hide-details
                        true-value="1"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="ans[i]"
                        label="No"
                        color="red"
                        hide-details
                        value="false"
                        true-value="0"
                      ></v-checkbox>
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-container>

            <v-container class="px-0">
              <v-row>
                <v-col cols="12" offset-md="8" md="4">
                  <v-btn
                    @click="save"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      ml-2
                    "
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api";
export default {
  data() {
    return {
      file: null,
      file_name: "",
      editedItem: {
        ref: "",
        manufacturing_site: "",
        office: "",
        name_of_tech_contact: "",
        tech_job_title: "",
        tech_phone: "",
        tech_fax: "",
        tech_email: "",

        name_of_sale_contact: "",
        sale_job_title: "",
        sale_phone: "",
        sale_fax: "",
        sale_email: "",

        product_name_supplied: "",
        product_code: "",
        country_of_origin: "",
        other_items_produced: "",

        created_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        company_certification: false,
      },

      overlay: false,
      questions: [],
      ans: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.questions = await api.getQuestion();
    },
    cancel() {
      this.$refs.frm.reset();
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    async save() {
      let array = [];
      if (this.ans.flat().length == this.questions.length) {
        this.overlay = true;
        this.questions.forEach((el, i) => {
          let status = false;
          if (this.ans[i] === "false") {
            status = false;
          } else {
            status = true;
          }
          let d = {
            question: el,
            qc_status: status,
          };
          array.push(d);
        });
        let req = {
          ...this.editedItem,
          child: array,
        };

        try {
          await axios
            .post("vendor-evaluation/", req)
            .then((res) => {
              console.log("saveData", res);

              if (this.editedItem.company_certification) {
                // File
                let bodyFormData = new FormData();
                bodyFormData.append("file", this.file, this.file.name);
                bodyFormData.append("id", res.data.id);
                api.uploadFile(bodyFormData);
                // File end
              }
              this.showSuccessAlert("Data Saved");
              this.$router.push({ name: "Vendor Evaluation" });
              this.overlay = false;

              this.clear();
            })
            .catch((err) => {
              throw new Error(err.response.data.message);
            });

          // const res = await api.saveData(req);
          // console.log("re", res);
        } catch (error) {
          this.showErrorAlert(error);
        }
      } else {
        this.showWarningAlert("Please Answer All Question");
      }
    },
    back() {
      this.$router.push({ name: "Vendor Evaluation" });
    },
    validate() {
      return this.$refs.frm.validate();
    },
    clear() {
      this.$refs.frm.reset();
      this.overlay = false;

      this.ans = [];
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.mnu_date = false;
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 3000,
        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },
};
</script>

<style></style>
